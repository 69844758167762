import React from 'react';

import { Modal } from '@src/components/Modal';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { setIncorrectNetworkModalOpen } from '@src/features/layout';

const IncorrectNetworkModal: React.FC = () => {
    const open = useAppSelector(
        (state) => state.layout.incorrect_network_modal_open,
    );
    const dispatch = useAppDispatch();

    if (!open) {
        return null;
    }

    return (
        <Modal onClose={() => dispatch(setIncorrectNetworkModalOpen(false))}>
            <div className="text-left">
                <h1 className="my-3">
                    <strong>INCORRECT NETWORK</strong>
                </h1>

                <p className="my-3">
                    We currently use Binance Smart Chain. Please change network
                    and retry to connect.
                </p>
            </div>
        </Modal>
    );
};

export default IncorrectNetworkModal;
